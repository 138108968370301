<template>
  <div class="mapviewer-chart" :style="[offsetStyle, posStyle]">
    <v-bottom-sheet
      v-model="sheetOpen"
      hide-overlay
      persistent
      no-click-animation
      attach=".pibot-mapviewer"
    >
      <!-- Button -->
      <template v-slot:activator="{ on: sheet }">
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="chart-button pa-1 ma-2"
              height="auto"
              min-width="0"
              :color="sheetOpen ? 'primary darken-1' : 'white'"
              small
              v-on="{...sheet, ...tooltip}"
              @click="buttonClick"
            >
              <v-icon>timeline</v-icon>
            </v-btn>
          </template>

          <span v-if="!sheetOpen">Show graph</span>
          <span v-else>Hide graph</span>
        </v-tooltip>
      </template>

      <!-- Radio Toggle -->
      <div>
        <v-radio-group v-if="sheetOpen" v-model="radio" class="radio-class py-1 px-2 my-0" dense hide-details mandatory row>
          <v-radio label="Depth/Orientation" value="depth" @change="changeChartType('depth')" />
          <v-radio label="Cathodic Protection" value="cp" @change="changeChartType('cp')" />
        </v-radio-group>
      </div>

      <!-- Sheet -->
      <v-sheet class="bottom-sheet text-center" :height="chartHeight" :max-height="chartHeight">
        <div id="bottom-sheet" keep-alive>
          <pibot-loading-overlay :loading="loading" style="width: 100%; height: 100%;" />
          <div id="chartId"></div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import LoadingOverlay from '@/components/overlays/LoadingOverlay'
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import { mapState, mapMutations, mapActions } from 'vuex'
import * as d3 from 'd3'

export default {
  name: 'pibot-mapbox-bottom-chart',
  mixins: [
    positionStyleMixin,
    offsetStyleMixin
  ],
  props: {
    theme: {
      type: String,
      default: 'light'
    }
  },
  components: {
    'pibot-loading-overlay': LoadingOverlay
  },
  data () {
    return {
      loading: false,
      initialized: false,
      radio: 'depth', // cp or depth
      chart: null,
      width: null,
      height: null,
      xScale: null,
      yScale: null,
      yScale2: null, // orientation
      xAxis: null,
      xGrid: null,
      length: 1000,
      lastActiveMouseLine: null,
      data: null,
      tooltipWidth: 75,
      tooltipMargin: 5,
      year: '',
      activeChartData: false
    }
  },
  computed: {
    ...mapState({
      chartHeight: state => state.mapbox.chartHeight,
      activeMouseLine: state => state.mapbox.activeMouseLine,
      mouseKp: state => state.mapbox.mouseKp,
      chartData: state => state.charts.chartData
    }),
    activeLayers () {
      return [...this.$store.state.mapbox.activeLayers]
    },
    themeColors () {
      return this.$vuetify.theme.currentTheme
    },
    chartColors () {
      const colors = {
        dataLine: this.themeColors.info,
        kpLine: this.themeColors.spd,
        thresholdLines: this.themeColors.error,
        gridLines: 'black',
        background: 'white',
        tooltip: '#3D3D3D',
        tooltipText: 'white'
      }
      switch (this.theme) {
        case 'dark':
          colors.gridLines = '#E8E8E8'
          colors.background = '#3D3D3D'
          colors.tooltip = 'white'
          colors.tooltipText = '#3D3D3D'
          return colors
        case 'light':
        default:
          return colors
      }
    },
    sheetOpen: {
      get () {
        return this.$store.state.mapbox.chartActive
      },
      set (value) {
        this.setChartActive(value)
      }
    },
    activeCpYears () {
      return this.activeLayers.filter(l => l.includes('cp-')).sort().reverse().map(e => e.split('-')[1])
    }
  },
  methods: {
    ...mapMutations({
      setChartActive: 'mapbox/setChartActive'
    }),
    ...mapActions({
      fetchChartData: 'charts/socket_getChartData'
    }),
    updateVerticalKpMarker (kp) {
      d3.zoomTransform(d3.select('div#chartId').node())
      d3.select('#vertical-kp-marker')
        .attr('x1', this.xScale(kp))
        .attr('y1', 0)
        .attr('x2', this.xScale(kp))
        .attr('y2', this.height)
    },
    updateTooltip (kp, type, reset = false) {
      const tooltipContainer = d3.select('#tooltip-container')
      if (reset) {
        tooltipContainer.select('text')
          .text('')
        return
      }
      // if tooltip would move past end of chart
      let xPixels = this.xScale(kp)
      if (xPixels < this.tooltipMargin + this.tooltipWidth / 2) xPixels = this.tooltipMargin
      else if (xPixels > this.width - this.tooltipMargin - this.tooltipWidth / 2) xPixels = this.width - this.tooltipMargin - this.tooltipWidth
      else xPixels = xPixels - this.tooltipWidth / 2

      tooltipContainer.select('rect')
        .attr('x', xPixels)

      if (this.initialized && this.data) {
        let valueString = null
        const data = this.data[this.data.length - 1]
        if (!data) return

        for (let i = 0; i < this.data.length; i++) {
          const el = this.data[i]
          if (1000 * el.KP >= kp) {
            if (i === 0) {
              valueString = type === 'depth' ? el.Altitude : el.CP
            } else {
              const prevEl = this.data[i - 1]
              const realEl = (Math.abs(1000 * el.KP - kp) <= Math.abs(1000 * prevEl.KP - kp)) ? el : prevEl
              valueString = type === 'depth' ? realEl.Altitude : realEl.CP
            }
            break
          }
        }
        if (valueString === null) valueString = type === 'depth' ? data.Altitude : data.CP
        valueString = Math.round(valueString, 2)
        tooltipContainer.select('text')
          .attr('x', xPixels + this.tooltipWidth / 2)
          .text(String(valueString) + (type === 'depth' ? ' m' : ' mV'))
      }
    },
    updateChart (pipeline, type, keepZoom) {
      if (!this.sheetOpen) return // Graph isn't shown, no need to continue

      this.loading = true
      if (!pipeline) {
        this.initChartType(type)
        this.updateAxes(type)
        this.loading = false
        return
      }

      const getChartData = () => {
        return new Promise((resolve, reject) => {
          if (this.chartData[type] && this.chartData[type][pipeline]) {
            const activeDataYears = Object.keys(this.chartData[type][pipeline])
            if (type === 'cp') {
              for (const activeCpYear of this.activeCpYears) {
                if (activeDataYears.includes(activeCpYear)) {
                  const chartData = this.chartData[type][pipeline][activeCpYear]
                  this.year = parseInt(activeCpYear)
                  resolve(chartData)
                }
              }
            } else if (type === 'depth') {
              const chartData = this.chartData[type][pipeline][Object.keys(this.chartData[type][pipeline])[0]]
              this.year = Object.keys(this.chartData[type][pipeline])[0]
              resolve(chartData)
            }
          }
          this.fetchChartData(type)
            .then(() => {
              let chartData
              if (this.chartData[type] && this.chartData[type][pipeline]) {
                const activeDataYears = Object.keys(this.chartData[type][pipeline])
                if (type === 'cp') {
                  for (const activeCpYear of this.activeCpYears) {
                    if (activeDataYears.includes(activeCpYear)) {
                      chartData = this.chartData[type][pipeline][activeCpYear]
                      this.year = parseInt(activeCpYear)
                      break
                    }
                  }
                } else if (type === 'depth') {
                  chartData = this.chartData[type][pipeline][Object.keys(this.chartData[type][pipeline])[0]]
                  this.year = Object.keys(this.chartData[type][pipeline])[0]
                }
              }
              if (chartData) {
                resolve(chartData)
              } else reject(Error('No data available'))
            })
            .catch((error) => {
              console.log('2')
              reject(error)
            })
        })
      }

      const self = this
      getChartData()
        .then((data) => {
          this.activeChartData = true
          this.data = data
          this.length = (data[data.length - 1]) ? Number(data[data.length - 1].KP) * 1000 : 0
          this.xScale = d3
            .scaleLinear()
            .range([0, this.width])
            .domain([Number(data[0].KP) * 1000, Number(data[data.length - 1].KP) * 1000])
          if (type === 'depth') {
            // get min and max altitude values based on data, round up or down to modulus(2)=0
            let max = Math.max.apply(Math, data.map(function (el) { return el.Altitude }))
            max = Math.ceil(max / 2) * 2
            let min = Math.min.apply(Math, data.map(function (el) { return el.Altitude }))
            min = Math.floor(min / 2) * 2
            this.yScale = d3
              .scaleLinear()
              .range([this.height, 0])
              .domain([min, max])
          }
          this.initChartType(type)
          this.updateAxes(type)
          this.resetZoom(keepZoom)
          this.updateTooltip(this.mouseKp, type)

          const svg = d3.select('div#chartId')

          // update chart title
          let title
          if (type === 'depth') title = 'Depth Profile - '
          else title = 'CP Survey - '
          title += pipeline.concat(' - ', parseInt(this.year))
          svg.select('.title').text(title)

          // line function
          const line = d3.line()
            .x(function (d) { return self.xScale(Number(d.KP) * 1000) })
            .y(function (d) { return self.yScale(Number(type === 'cp' ? d.CP : d.Altitude)) })
            .curve(d3.curveMonotoneX)
          const area = d3.area()
            .x(function (d) { return self.xScale(Number(d.KP) * 1000) })
            .y1(function (d) { return self.yScale(Number(type === 'cp' ? d.CP : d.Altitude)) })
            .y0(d => self.yScale(0))
          // transition line
          self.chart
            .select('.data-line')
            .attr('d', line(data))
          self.chart
            .select('.area')
            .attr('d', area(data))
            .style('opacity', type === 'depth' ? 0.25 : 0)

          this.loading = false
        })
        .catch((error) => {
          console.error(error)
          this.activeChartData = false
          // reset chart
          this.xScale = d3
            .scaleLinear()
            .range([0, this.width])
            .domain([0, 1000])
          if (type === 'depth') {
            this.yScale = d3
              .scaleLinear()
              .range([this.height, 0])
              .domain([-45, 0])
          }
          this.initChartType(type)
          this.updateAxes(type)
          this.resetZoom(keepZoom)
          this.updateTooltip(this.mouseKp, type, true)
          const svg = d3.select('div#chartId')
          // line function
          const line = d3.line()
            .x(function (d) { return self.xScale(Number(d.KP) * 1000) })
            .y(function (d) { return self.yScale(Number(type === 'cp' ? d.CP : d.Altitude)) })
            .curve(d3.curveMonotoneX)
          const area = d3.area()
            .x(function (d) { return self.xScale(Number(d.KP) * 1000) })
            .y1(function (d) { return self.yScale(Number(type === 'cp' ? d.CP : d.Altitude)) })
            .y0(d => self.yScale(0))
          // beyond chart data
          const data = [{ KP: 0, CP: -2000, Altitude: 0 }, { KP: 1, CP: -2000, Altitude: 0 }]
          self.chart
            .select('.data-line')
            .attr('d', line(data))
          self.chart
            .select('.area')
            .attr('d', area(data))
            .style('opacity', type === 'depth' ? 0.25 : 0)
          // update chart title
          const title = type === 'depth' ? `Depth Profile - ${pipeline} - ${error.message}` : `CP Survey - ${pipeline} - ${error.message}`
          svg.select('.title').text(title)

          this.loading = false
        })
    },
    resetZoom (keepZoom) {
      const chart = this.chart
      const xAxis = this.xAxis
      const xGrid = this.xGrid
      const xScale = this.xScale

      function zoomed (event) {
        const transform = event.transform
        const transformString =
          'translate(' + transform.x + ', 0)scale(' + transform.k + ', ' + '1)'
        chart.attr('transform', transformString)
        d3.select('#x-axis').call(xAxis.scale(transform.rescaleX(xScale)))
        d3.select('#x-grid').call(xGrid.scale(transform.rescaleX(xScale)))
        // gY.call(yAxis.scale(d3.event.transform.rescaleY(yScale)))
        // gYG.call(yGrid.scale(d3.event.transform.rescaleY(yScale)))
        d3.select('#vertical-kp-marker').style('stroke-width', 2 / transform.k)
      }

      // apply zoom function
      const zoom = d3
        .zoom()
        .extent([[0, 0], [this.width, this.height]])
        .translateExtent([[0, 0], [this.width, this.height]])
        .scaleExtent([1, 16])
        .on('zoom', zoomed)
      d3.select('div#chartId')
        .call(zoom)

      // reset zoom level
      if (!keepZoom) {
        zoom.translateTo(d3.select('div#chartId'), 0, 0)
        zoom.scaleTo(d3.select('div#chartId'), 1)
      } else { // keep zoom level, but rescale new axis and grid to this zoom level
        const transformString = chart.attr('transform')
        if (transformString) {
          const x = transformString.split('(')[1].split(',')[0]
          const k = transformString.split('scale(')[1].split(',')[0]
          const eventTransform = d3.zoomIdentity.translate(x, 0).scale(k)
          d3.select('#x-axis').call(xAxis.scale(eventTransform.rescaleX(xScale)))
          d3.select('#x-grid').call(xGrid.scale(eventTransform.rescaleX(xScale)))
        }
      }
    },
    updateAxes (type) {
      // axis functions
      const xAxis = this.xAxis = d3.axisBottom(this.xScale)
      const yAxis = d3.axisLeft(this.yScale)
      d3.select('#x-axis')
        .call(xAxis)
      d3.select('#y-axis')
        .call(yAxis)
      if (type === 'cp') d3.select('#y2-axis').style('opacity', 0)
      else if (type === 'depth') d3.select('#y2-axis').style('opacity', 1)

      // grid functions
      const xGrid = this.xGrid = d3
        .axisTop()
        .scale(this.xScale)
        .tickSize(-this.height, 0, 0)
        .tickFormat('')
      const yGrid = d3
        .axisLeft()
        .scale(this.yScale)
        .tickSize(-this.width, 0, 0)
        .tickFormat('')
      d3.select('#x-grid')
        .call(xGrid)
      d3.select('#y-grid')
        .call(yGrid)
    },
    initChartType (type) {
      const chart = this.chart
      let yScale
      switch (type) {
        case 'cp':
          d3.select('#x-label')
            .text('Distance [m]')
          d3.select('#y-label')
            .text('Potential [mV]')
          d3.select('#y2-label')
            .text('')
          d3.select('#title')
            .text('CP Survey')
          yScale = this.yScale = d3
            .scaleLinear()
            .range([this.height, 0])
            .domain([-700, -1200])
          chart
            .append('line')
            .attr('clip-path', 'url(#clip)')
            .attr('class', 'threshold-line')
            .attr('x1', 0)
            .attr('y1', yScale(-800))
            .attr('x2', this.xScale(this.length))
            .attr('y2', yScale(-800))
            .style('stroke', this.chartColors.thresholdLines)
            .style('stroke-width', 2)
          chart
            .append('line')
            .attr('clip-path', 'url(#clip)')
            .attr('class', 'threshold-line')
            .attr('x1', 0)
            .attr('y1', yScale(-1100))
            .attr('x2', this.xScale(this.length))
            .attr('y2', yScale(-1100))
            .style('stroke', this.chartColors.thresholdLines)
            .style('stroke-width', 2)
          break
        case 'depth':
          d3.select('#x-label')
            .text('Distance [m]')
          d3.select('#y-label')
            .text('Depth [m]')
          d3.select('#y2-label')
            .text('Orientation [hours]')
          d3.select('#title')
            .text('Depth Profile')
          chart.selectAll('line.threshold-line').remove()
          break
        default:
          break
      }
      this.initialized = true
    },
    changeChartType (type) {
      const keepZoom = true
      this.updateChart(this.lastActiveMouseLine, type, keepZoom)
    },
    initSvg () {
      // margins around chart for labels, title
      const margin = { top: 50, right: 50, bottom: 40, left: 80 }
      const width = this.width =
        document.getElementById('bottom-sheet').offsetWidth -
        margin.left -
        margin.right
      const height = this.height =
        document.getElementById('bottom-sheet').offsetHeight -
        margin.top -
        margin.bottom

      const svg = d3.select('div#chartId')
        .append('div')
        .classed('svg-container', true)
        .append('svg')
        .attr('preserveAspectRatio', 'none')
        .attr('viewBox', `0 0 ${width + 150} ${height + 100}`)// '0 0 600 400')
        .style('max-height', '29vh')
        .classed('svg-content-responsive', true)

      // title and axis labels
      svg
        .append('text')
        .attr('id', 'title')
        .attr('class', 'title')
        .attr('x', width / 2 + margin.left)
        .attr('y', 2 * margin.top / 3)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 50)
      svg
        .append('text')
        .attr('id', 'y-label')
        .attr('class', 'axisLabel')
        .attr('x', -(height / 2) - margin.top)
        .attr('y', margin.left / 3)
        .attr('transform', 'rotate(-90)')
        .attr('text-anchor', 'middle')
      svg
        .append('text')
        .attr('id', 'x-label')
        .attr('class', 'axisLabel')
        .attr('x', width / 2 + margin.left)
        .attr('y', height + margin.top + 4 * margin.bottom / 5)
        .attr('text-anchor', 'middle')
      svg
        .append('text')
        .attr('id', 'y2-label')
        .attr('class', 'axisLabel')
        .attr('x', -(height / 2) - margin.top)
        .attr('y', margin.left + width + 3 * margin.right / 4)
        .attr('transform', 'rotate(-90)')
        .attr('text-anchor', 'middle')

      // chart group
      svg
        .append('g')
        .append('clipPath')
        .attr('id', 'clip')
        .append('rect')
        .attr('width', width)
        .attr('height', height)
      const chart = this.chart = svg
        .append('g')
        .attr('clip-path', 'url(#clip)')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .append('g')

      // rect to change background color
      chart
        .append('rect')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('fill', this.chartColors.background)

      // grid lines
      svg
        .append('g')
        .attr('id', 'x-grid')
        .attr('class', 'grid')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .style('color', this.chartColors.gridLines)
        .style('stroke-width', 0.5)
        .style('opacity', 0.7)
        .style('shape-rendering', 'crispEdge')
      svg
        .append('g')
        .attr('id', 'y-grid')
        .attr('class', 'grid')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .style('color', this.chartColors.gridLines)
        .style('stroke-width', 0.5)
        .style('opacity', 0.7)
        .style('shape-rendering', 'crispEdge')

      // axes
      svg
        .append('g')
        .attr('id', 'x-axis')
        .attr('class', 'axis')
        .attr('transform', 'translate(' + margin.left + ',' + (margin.top + height) + ')')
      svg
        .append('g')
        .attr('id', 'y-axis')
        .attr('class', 'axis')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // orientation axis, build it but hide it
      this.yScale2 = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, 12])
      svg
        .append('g')
        .attr('id', 'y2-axis')
        .attr('class', 'axis')
        .attr('transform', 'translate(' + (margin.left + width) + ',' + margin.top + ')')
        .call(d3.axisRight(this.yScale2))
        .style('opacity', 0)

      // Tooltip
      const tooltip = svg
        .append('g')
        .attr('id', 'tooltip-container')
        .attr('transform', 'translate(' + (margin.left) + ',' + margin.top + ')')
      tooltip
        .append('rect')
        .attr('rx', 5)
        .attr('x', -300)
        .attr('y', this.tooltipMargin)
        .attr('width', this.tooltipWidth)
        .attr('height', '30px')
        .attr('fill', this.chartColors.tooltip)
      tooltip
        .append('text')
        .attr('id', 'tooltip-text')
        .attr('x', -300)
        .attr('y', 20)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('font-height', 20)
        .style('fill', this.chartColors.tooltipText)
        .text('hoi')

      // setup data line so it can be updated easily
      chart
        .append('path')
        .attr('id', 'data-line')
        .attr('class', 'data-line')
        .style('fill', 'none')
        .style('stroke', this.chartColors.dataLine)
        .style('stroke-width', 2)
      // vertical kp line
      chart
        .append('line')
        .attr('clip-path', 'url(#clip)')
        .attr('id', 'vertical-kp-marker')
        .attr('class', 'vertical-kp-marker')
        .style('stroke', this.chartColors.kpLine)
        .style('stroke-width', 1.5)
      // area under depth chart
      chart
        .append('path')
        .attr('class', 'area')
        .style('fill', '#B7E0FF')

      this.initialized = true

      // Default radio is depth so we can call it here
      this.xScale = d3
        .scaleLinear()
        .range([0, this.width])
        .domain([0, this.length])
      this.yScale = d3
        .scaleLinear()
        .range([this.height, 0])
        .domain([-20, 5])
      this.initChartType(this.radio)
      this.updateAxes(this.radio)
    },
    buttonClick () {
      if (!this.initialized) {
        setTimeout(() => {
          this.initSvg()
        }, 200)
      }
    }
  },
  watch: {
    activeMouseLine (val) {
      if (val) this.lastActiveMouseLine = val
      if (this.initialized && val) this.updateChart(val, this.radio, false)
    },
    mouseKp (val) {
      if (this.sheetOpen && this.initialized) {
        if (this.activeChartData) {
          this.updateVerticalKpMarker(val)
          this.updateTooltip(val, this.radio)
        }
      }
    }
    // activeCpYear (v) {
    //   if (this.initialized) this.updateChart(this.lastActiveMouseLine, this.radio, false)
    // }
  },
  mounted () {
    const elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = 'hidden'
  },
  destroyed () {
    const elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = null
  }
}
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}

.chart-button {
  position: relative;
}
.radio-class {
  /* background: white; */
  /* border: 1px; */
  /* border-radius: 5%; */
  /* border-color: black; */
  /* border-width: 1px; */
  height: fit-content;
  position: absolute;
  top: unset;
  left: 0px;
  bottom: 27vh;
  right: unset;
  z-index: 100;
}

#bottom-sheet {
  position: relative;
  height: 100%;
}

.svg-container {
  display: inline-block;
  position: relative;
  /* width: 100%; */
  padding-bottom: 100%;
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  /* top: 10px; */
  top: 0;
  left: 0;
}

text.axisLabel {
  pointer-events: none;
}

text.title {
  pointer-events: none;
}

text.x-axis {
  pointer-events: none;
  font-size: 40px;
}
</style>
